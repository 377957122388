import { inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { SortDirection } from '@angular/material/sort';
import { environment } from '@env/environment';
import { skip, switchMap } from 'rxjs';
import { Logger, UserSettings, UserSettingsQuery } from 'timeghost-api';
import { debounceTimeAfterFirst } from './debounceAfterTime';
import { Encryption as AppEncryption } from './encryption';
import {
  RXValue,
  RxOptions,
  createRxValue,
  distinctUntilChangedJson,
  parseJson,
  stringify,
  takeUntilDestroyed,
} from './utils';

const log = new Logger('EncryptedRx');
export let APP_ENCRYPTION_KEY: string = null;
export function initRxEncryption(user: UserSettings) {
  if (APP_ENCRYPTION_KEY) return;
  if (user?.id && user.created) {
    APP_ENCRYPTION_KEY = `${user.id}#${new Date(user.created).toISOString()}#${environment.adalConfig.clientId}`;
    log.debug('initialized', APP_ENCRYPTION_KEY);
  } else throw new Error('failed to instantiate security key');
}
export type RxParserFunction<T> = <R = T>(obj: T, key: string) => R;
export interface RxCacheOptions<T> extends Omit<RxOptions<T>, 'distinct'> {
  customStorage?: Storage;
  parser?: RxParserFunction<T>;
}
export const createEncryptedRxValue = <T, R extends RXValue<T> = RXValue<T>>(
  key: string | (() => string),
  defaultValue: T,
  options?: RxCacheOptions<T>,
): R => {
  const enc = new AppEncryption({ secret: APP_ENCRYPTION_KEY });

  const user = inject(UserSettingsQuery);
  let prefix = user.getValue().defaultWorkspace;
  const getStoreKey = (_prefix: string): [string, string] => {
    const _inputKey = `${typeof key === 'function' ? key() : key}`;
    const _storeKey = `rx.${_prefix || ''}.${_inputKey}`.replace(/^rx\.\./, '');
    return [_storeKey, _inputKey];
  };
  let [storeKey, inputKey] = getStoreKey(prefix);
  user
    .select()
    .pipe(takeUntilDestroyed())
    .subscribe((x) => {
      prefix = x.defaultWorkspace;
      [storeKey, inputKey] = getStoreKey(prefix);
    });
  const storage = options?.customStorage ?? localStorage;
  const log = new Logger(`EncryptedRX:${inputKey}`);
  const customParser = options?.parser ? options.parser : null;
  let persistetData: T;
  try {
    let tempValue: string;
    if ((tempValue = storage.getItem(storeKey)) && !(persistetData = enc.decrypt<T>(tempValue, inputKey)))
      persistetData = defaultValue;
    else if (customParser && typeof persistetData === 'object')
      persistetData = customParser(persistetData, inputKey) ?? defaultValue;

    log.debug({ persistetData, defaultValue, tempValue });
  } catch (ex) {
    persistetData = defaultValue;
    log.error('failed to parse storage value', ex);
  }
  if (persistetData === undefined) persistetData = defaultValue;
  const instance = createRxValue(persistetData, options);
  const shouldSkipFirst = persistetData !== defaultValue;
  instance
    .asObservable()
    .pipe(
      (s) => (shouldSkipFirst ? s.pipe(skip(1)) : s),
      distinctUntilChangedJson(),
      debounceTimeAfterFirst(1000),
      switchMap(async (fetchedValue) => {
        const newValue = enc.encrypt(fetchedValue, null, inputKey);
        if (!environment.production)
          log.debug(this, {
            value: fetchedValue,
            encrypted: newValue,
            decrypted: enc.decrypt(newValue, inputKey),
            shouldSkipFirst,
            storeKey,
          });
        await Promise.resolve(storage.setItem(storeKey, newValue));
        return true;
      }),
      takeUntilDestroyed(),
    )
    .subscribe();
  return instance as R;
};

export function createRxValueWithCache<T, R extends RXValue<T> = RXValue<T>>(
  key: string | (() => string),
  defaultValue: T,
  options?: RxCacheOptions<T>,
): R {
  if (!options) options = {};
  const user = inject(UserSettingsQuery);
  let prefix = user.getValue().defaultWorkspace;
  const getStoreKey = (_prefix: string): [string, string] => {
    const _inputKey = `${typeof key === 'function' ? key() : key}`;
    const _storeKey = `rx.${_prefix || ''}.${_inputKey}`.replace(/^rx\.\./, '');
    return [_storeKey, _inputKey];
  };
  let [storeKey, inputKey] = getStoreKey(prefix);
  user
    .select()
    .pipe(takeUntilDestroyed())
    .subscribe((x) => {
      prefix = x.defaultWorkspace;
      [storeKey, inputKey] = getStoreKey(prefix);
    });
  const storage = options.customStorage ?? localStorage;
  let persistetData: T;

  try {
    let tempValue: string;
    if ((tempValue = storage.getItem(storeKey)) && !(persistetData = parseJson(tempValue)))
      persistetData = defaultValue;
    else if (!tempValue) persistetData = defaultValue;
  } catch (ex) {
    log.error(ex);
    persistetData = defaultValue;
  }
  if (options.parser) persistetData = options.parser(persistetData, inputKey);
  const instance = createRxValue(persistetData, options);
  const shouldSkipFirst = persistetData !== defaultValue;
  instance
    .asObservable()
    .pipe(
      (s) => (shouldSkipFirst ? s.pipe(skip(1)) : s),
      distinctUntilChangedJson(),
      debounceTimeAfterFirst(1000),
      switchMap(async (newValue) => {
        if (!environment.production)
          log.debug(this, {
            value: newValue,
            shouldSkipFirst,
          });
        await Promise.resolve(storage.setItem(storeKey, stringify(newValue)));
        return true;
      }),
      takeUntilDestroyed(),
    )
    .subscribe();
  return instance as R;
}
export type RxSortType =
  | { type: 'filter'; active: string }
  | { type: 'sort'; active: string; direction: SortDirection };
export function createRxSort<T extends RxSortType = RxSortType, R extends RXValue<T> = RXValue<T>>(
  key: string,
  defaultValue: T,
  options?: RxCacheOptions<T>,
): { store: R; eventHandler: (ev: string) => void; sortHandler: (ev: string) => void; value: string } {
  const store = createRxValueWithCache<T, R>(key, defaultValue, options);
  const eventHandler = (_value: string) => {
    if (!_value) return;
    const [type, value] = _value.split(':', 2);
    store.merge({ active: value, type } as any);
  };
  const sortHandler = (value: string) => {
    if (!value) return;
    store.merge({ direction: value } as any);
  };
  return new (class {
    public store: R = store;
    public eventHandler = eventHandler;
    public sortHandler = sortHandler;

    get value() {
      const storeValue = this.store.value;
      return storeValue ? `${storeValue.type}:${storeValue.active}` : null;
    }
  })();
}

export module RXParser {
  // @ts-ignore
  type RxParserFactory = <T>() => RxParserFunction<T>;
  export function DateParser<T>(...props: (keyof T)[]): RxParserFunction<T> {
    return <R = T>(obj: T): R => {
      if (!obj) return obj as any as R;
      (props as any as string[]).forEach((key) => {
        obj[key] = new Date(obj[key]);
      });
      return obj as any as R;
    };
  }
  export function SameContextVerify<T>(workspaceId: string): RxParserFunction<T> {
    return <R = T>(obj: { [key: string]: any }): R => {
      if (!obj) return obj as any as R;
      if ((('workspace' in obj) as any) && obj.workspace?.id !== workspaceId) {
        return null as R;
      }
      return obj as any as R;
    };
  }
}

export function createRxControlWithCache<T = any>(name: string, defaultValue: T) {
  const control = new FormControl<T>(defaultValue);
  const rx = createRxValueWithCache(`control.${name}`, defaultValue);
  control.setValue(rx.value);
  control.valueChanges.subscribe((v) => rx.next(v));
  return control;
}
export function createRxGroupWithCache<TControl extends { [K in keyof TControl]: AbstractControl<any, any> } = any>(
  formGroup: FormGroup<TControl>,
  name: string,
) {
  const control = formGroup;
  const rx = createRxValueWithCache<any>(`group.${name}`, control.value);
  control.setValue(rx.value);
  control.valueChanges.subscribe((v) => rx.next(v));
  return control;
}

export {};
